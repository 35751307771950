<template>
    <div class="menu-container" style="margin-right: 0vw; margin-left: auto;">
        <!--  @click="toggleMenu" -->
        <div @click="toggleMenu" class="hamburger">
            <div :class="{ 'line': true, 'top-line': true, 'open': showMenu }" :style="hamburgur_style"></div>
            <div :class="{ 'line': true, 'middle-line': true, 'open': showMenu }" :style="hamburgur_style"></div>
            <div :class="{ 'line': true, 'bottom-line': true, 'open': showMenu }" :style="hamburgur_style"></div>
        </div>
        <div v-if="showMenu" class="mobile-nav-items" >
            <div class="nav-item" v-for="(item, index) in items" :key="index" @click="goToRoute(item.url)"
                :style="nav_item_style">
                <div>{{ item.name }}</div>
                <!-- <div class="down-arrow" v-if="item.options">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#084d80"
                        height="8px" width="8px" version="1.1" id="Layer_1" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </div> -->
            </div>
            <div style="height: 30px;">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileNavItem',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        styling: {
            type: Object,
            default: () => ({
                bg_color: '#084d80',
                txt_color: '#fff',
                font_1: '2rem',
                font_2: '1.5rem',
                font_3: '1.2rem',
                font_4: '1rem',
                font_5: '0.8rem',
                font_width_1: '700',
                font_width_2: '550',
                font_width_3: '500',
            })
        }
    },
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
            console.log(this.items)
        },
        goToRoute(path) {
            console.log(this.items)
            if (this.$router && path) {
                this.$router.push(path);
            } else {
                console.log(this.items)
                console.error("Router instance not found or path undefined.");
            }
            this.toggleMenu()
        }
    },
    computed: {
        hamburgur_style() {
            return {
                'background-color': this.styling.txt_color,
            }
        },
        nav_item_style() {
            return {
                'border-bottom': '1px solid ' + this.styling.bg_color,
                'color': this.styling.bg_color,
            }
        }
    }
}
</script>

<style scoped>
.menu-container {
    position: relative;
    width: 26px;
    
}

.hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 26px;
    height: 25px;
    z-index: 1001;
}

.line {
    height: 2px;
    transition: all 0.5s ease;
    width: 26px;
    position: absolute;
    left: 0;
}

.top-line {
    top: 2px;
}

.middle-line {
    top: 11px;
}

.bottom-line {
    top: 20px;
}

.open.top-line {
    transform: rotate(45deg);
    transform-origin: top left;
}

.open.middle-line {
    width: 0%;
    opacity: 1;
}

.open.bottom-line {
    transform: rotate(-45deg);
    transform-origin: bottom left;
}

.mobile-nav-items {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 70px;
    padding-left: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: white;
    opacity: 1;
    z-index: 1001;
}

.nav-item {
    padding: 12px;
    cursor: pointer;
    text-align: left;
    width: calc(100% - 65px);
    font-weight: 600;
    font-size: 1rem;
    display: flex;
}

.down-arrow {
    margin-left: auto;
}
</style>
