// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    globalSelectedItems: [] // Global list of selected items
  },
  getters: {
    isGlobalSelectedItemsEmpty(state) {
      return state.globalSelectedItems.length === 0;
    },
    globalSelectedItems(state) {
      return state.globalSelectedItems;
    }
  },
  mutations: {
    addItem(state, item) {
      if (!state.globalSelectedItems.includes(item)) {
        state.globalSelectedItems.push(item);
      }
    },
    removeItem(state, item) {
      const index = state.globalSelectedItems.indexOf(item);
      if (index > -1) {
        state.globalSelectedItems.splice(index, 1);
      }
    },
    clearItems(state, items) {
      state.globalSelectedItems = state.globalSelectedItems.filter(item => !items.includes(item));
    }
  },
  actions: {
    addItem({ commit }, item) {
      commit('addItem', item);
    },
    removeItem({ commit }, item) {
      commit('removeItem', item);
    },
    clearItems({ commit }, items) {
      commit('clearItems', items);
    }
  }
});
