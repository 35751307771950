<template>
	<div :class="['nav-item', isActive ? 'active' : '']" @mouseover="hover = true" @mouseleave="hover = false">
		<div class="item" @click="goToRoute(url)">
			<div class="display-item-name">
				{{ name }}
			</div>
			<div class="down-arrow" v-if="options">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff"
					height="8px" width="8px" version="1.1" id="Layer_1" viewBox="0 0 330 330" xml:space="preserve">
					<path id="XMLID_225_"
						d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
				</svg>
			</div>
		</div>
		<div v-if="hover" class="dropdown" :style="{ 'font-weight': styling.font_width_2, 'font-size': styling.font_5 }">
			<ul>
				<li v-for="item in items" :key="item" @click="goToRoute(item.url)">
					<div>
						{{ item.name }}
					</div>
					<div class="down-arrow" v-if="item.options">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
							fill="#084d80" height="8px" width="8px" version="1.1" id="Layer_1" viewBox="0 0 330 330"
							xml:space="preserve">
							<path id="XMLID_225_"
								d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
						</svg>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NavItem',
	props: {
		name: {
			type: String,
			default: () => "NavItem"
		},
		options: {
			type: Boolean,
			default: () => false
		},
		items: {
			type: Array,
			default: () => []
		},
		styling: {
			type: Object,
			default: () => ({
				bg_color: '#084d80',
				txt_color: '#fff',
				font_1: '2rem',
				font_2: '1.5rem',
				font_3: '1.2rem',
				font_4: '1rem',
				font_5: '0.8rem',
				font_width_1: '700',
				font_width_2: '550',
				font_width_3: '500',
			})
		},
		url: {
			type: String,
			default: () => "/"
		}
	},
	data() {
		return {
			showDropdown: false,
			hover: false
		};
	},
	methods: {
		toggleDropdown() {
			this.showDropdown = !this.showDropdown;
			if (this.showDropdown) {
				document.addEventListener('click', this.handleClickOutside, true);
			} else {
				document.removeEventListener('click', this.handleClickOutside, true);
			}
		},
		handleClickOutside(event) {
			if (!this.$el.contains(event.target)) {
				this.showDropdown = false;
				document.removeEventListener('click', this.handleClickOutside, true);
			}
		},
		goToRoute(path) {
			if (this.$router && path) {
				this.$router.push(path);
			} else {
				console.error("Router instance not found or path undefined.");
			}
		},
		destroyed() {
			document.removeEventListener('click', this.handleClickOutside, true);
		}
	},
	computed: {
		computedStyle() {
			if (this.hover) {
				return {
					'font-size': this.styling.font_4,
					'color': this.styling.bg_color,
					'background-color': this.styling.txt_color,
					'font-weight': this.styling.font_width_3,
					'border-bottom': "#eee"
				};
			} else {
				return {
					'font-size': this.styling.font_4,
					'color': this.styling.txt_color,
					'background-color': this.styling.bg_color,
					'font-weight': this.styling.font_width_3,
					'border-bottom': "#eee"
				};
			}
		},
		isActive() {
			return this.$route.path !== '/' && this.$route.path === this.url;
		}
	}
}
</script>

<style scoped>
.nav-item {
	position: relative;
	width: 80px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	transition: background-color 0.0s, color 0.0s;
	color: white;
	font-weight: 500;
}
.nav-item:hover {
	transition: background-color 0.0s, color 0.0s;
	color: #084d80;
	background-color: white;
	font-weight: 550;
}
.item {
	display: grid;
	place-items: center;
	height: 70px;
	width: 80px;
	display: flex;
	text-align: center;
	justify-content: center;
}

.dropdown {
	position: absolute;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 1000;
	text-align: left;
}

.dropdown ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.dropdown li {
	padding: 8px 12px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
	opacity: 0;
	transform: scaleY(0);
	animation: dropItem 0.0s ease forwards;
	display: flex;
}

.dropdown li:hover {
	background-color: #f5f5f5;
}

.down-arrow {
	margin-left: 5px;
	width: 10px;
}

.display-item-name {
	width: fit-content;
	text-align: center;
}

.nav-item.active {
	background-color: #fff;
	color: #084d80;
	font-weight: 550;
}

@keyframes drop {
	from {
		opacity: 0;
		transform: translateY(-30px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes dropItem {
	0% {
		opacity: 0;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform: scaleY(1);
	}
}
</style>