<template>
	<div id="site-lg" style=" display: flex; width: 320px;" @click="go_to_home()">
		<div class="mp-logo" v-html="img_data.img_svg"></div>
		<div class="writing" :style="writing_style">{{ text_data.primary }}
			<span class="plus">+</span>
			<span class="type">{{ current_text }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SiteLogo',
	props: {
		styling: {
			type: Object,
			default: () => ({
				bg_color: '#084d80',
				txt_color: '#fff',
				font_1: '2rem',
				font_2: '1.5rem',
				font_3: '1.2rem',
				font_4: '1rem',
				font_5: '0.8rem',
				font_width_1: '700',
				font_width_2: '550',
				font_width_3: '500',
			})
		},
		img_data: {
			type: JSON,
			default: () => ({
				img_svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="40px" height="40px" viewBox="254.384 375.649 40 40" enable-background="new 254.384 375.649 140 140" xml:space="preserve"><path fill="#FFFFFF" d="M254.384,375.649v40h40v-40H254.384z M261.734,408.148h-3.621l4.884-24.941l7.427,16.818l-1.823,3.992  l-4.373-9.889L261.734,408.148z M275.791,408.148v-2.901h3.749l-1.774-11.103l-6.385,14.003h-6.036v-2.901h3.782l10.078-22.041  l4.388,24.941H275.791z M290.574,394.204c-0.95,1.356-2.342,2.361-3.933,2.838c-0.917,0.273-3.876,0.769-3.876,0.769l-0.576-3.259  c0.893-0.157,1.795-0.292,2.682-0.481c0.843-0.179,1.693-0.454,2.346-1.037c0.671-0.597,1.142-1.408,1.327-2.287  c0.107-0.518,0.116-1.053,0.025-1.575c-0.095-0.519-0.286-1.021-0.563-1.472c-0.475-0.763-1.196-1.362-2.031-1.694  c-0.815-0.324-1.707-0.291-2.559-0.168c-0.897,0.128-1.791,0.309-2.685,0.469l-0.576-3.257c0-0.002,1.012-0.181,1.076-0.193  c0.938-0.168,1.875-0.361,2.83-0.418c1.659-0.099,3.311,0.368,4.669,1.316c1.646,1.148,2.746,2.865,3.099,4.839l0,0v0.002l0,0  C292.168,390.569,291.725,392.563,290.574,394.204z"></path></svg>`,
				logoAltText: 'MP Logo - Default',
				logoWidth: 100,
				logoHeight: 50,
				home_url: 'https://mpengs.com/',
			})
		},
		text_data: JSON,
		mobile_screen: {
			type: Boolean,
			default: () => false
		},
	},
	data() {
		return {
			current_text: '',
			text_index: 0,
			char_index: 0,
			is_deleting: false,
		};
	},
	computed: {
		writing_style() {
			if (this.mobile_screen == false) {
				return {
					'font-size': this.styling.font_3,
					'font-weight': this.styling.font_width_3,
					'color': this.styling.txt_color,

				}
			}
			else {
				return {
					'font-size': this.styling.font_2,
					'font-weight': this.styling.font_width_2,
					'color': this.styling.txt_color,
				}
			}
		}
	},
	mounted() {
		this.typing();
	},
	methods: {
		typing() {
			const speedForward = 100;
			const speedBackspace = 50;
			const pauseEnd = 2000;
			if (this.is_deleting) {
				this.current_text = this.text_data.secondary[this.text_index].substring(0, this.char_index--);
			} else {
				this.current_text = this.text_data.secondary[this.text_index].substring(0, this.char_index++);
			}
			if (!this.is_deleting && this.char_index === this.text_data.secondary[this.text_index].length) {
				setTimeout(() => {
					this.is_deleting = true;
				}, pauseEnd);
			}
			if (this.is_deleting && this.char_index === 0) {
				this.is_deleting = false;
				this.text_index = (this.text_index + 1) % this.text_data.secondary.length;
			}
			setTimeout(() => {
				this.typing();
			}, this.is_deleting ? speedBackspace : speedForward);
		},
		go_to_home() {
			if (this.$router ) {
				this.$router.push("/");
			} else {
				console.error("Router instance not found or path undefined.");
			}
		},
	},
}
</script>

<style scoped>
.mp-logo {
	display: grid;
	place-items: center;
}

.writing {
	display: flex;
	place-items: center;
	max-width: 265px;
	margin-left: 15px;
	margin-right: 50px;
}

.plus {
	margin-left: 5px;
	margin-right: 5px;
	font-weight: 500;
	color: #ff6404;
}

.type {
	position: relative;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
#site-lg{
	margin-left: 15px;
}
@media (max-width: 1120px) {
	#site-lg{
		margin-left: 5vw;
	}
}
</style>